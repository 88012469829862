<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    style="enable-background:new 0 0 512.001 512.001;"
    xml:space="preserve"
  >
    <polygon
      style="fill:#65B4BB;"
      points="174.05,340.508 160.416,386.845 137.647,464.288 58.341,464.288 66.197,367.775 
	70.138,319.461 "
    />
    <polygon
      style="fill:#57A3A7;"
      points="174.05,340.508 160.416,386.845 133.833,381.46 66.197,367.775 70.138,319.461 "
    />
    <polyline
      style="fill:#82DCC7;"
      points="68.636,184.99 0,184.99 0,298.151 68.241,298.151 "
    />
    <path
      style="fill:#FED159;"
      d="M456.234,185.845l0.011,0.011c14.27,0.004,28.531,5.445,39.419,16.331
	c21.775,21.775,21.785,57.065,0.02,78.828c-10.882,10.882-25.14,16.315-39.41,16.311l-0.011,0.011"
    />
    <path
      style="fill:#F6C454;"
      d="M495.684,256.311c-10.882,10.882-25.14,16.315-39.41,16.311l-0.011,0.011l-0.023-86.781l-0.006-0.006
	l0.029,111.495l0.011-0.011c14.27,0.004,28.528-5.429,39.41-16.311c14.04-14.042,19.005-33.709,14.922-51.749
	C508.363,239.178,503.399,248.596,495.684,256.311z"
    />
    <polygon
      style="fill:#F64B4A;"
      points="384.942,389.531 345.029,381.446 214.09,354.952 160.442,344.081 79.932,327.786 
	73.128,326.404 32.607,318.193 33.278,164.612 344.953,101.665 384.866,93.605 "
    />
    <g>
      <polygon
        style="fill:#E84242;"
        points="384.942,389.531 345.029,381.446 344.953,101.665 384.866,93.605 	"
      />
      <polygon
        style="fill:#E84242;"
        points="32.825,269.143 383.487,314.775 384.946,389.535 32.612,318.19 	"
      />
    </g>
    <polygon
      style="fill:#65B4BB;"
      points="462.639,422.323 462.563,422.335 378.619,435.437 378.606,389.531 378.594,342.293 
	378.53,93.605 378.518,47.713 462.55,60.865 462.563,185.849 462.601,297.337 "
    />
    <polygon
      style="fill:#57A3A7;"
      points="462.563,422.335 378.619,435.437 378.606,357.854 462.55,355.447 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
